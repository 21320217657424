import BaseController from '../base_controller'

export default class CitationSwitcher extends BaseController {
  static targets = ['scrollContainer', 'leftButton', 'rightButton', 'citationContainer']
  static values = {
    citationWidth: Number,
    citationIds: Array,
    focusedCitationId: Number
  }

  connect () {
    super.connect()

    this.updateCurrentCitationIds()

    this.currentIndex = this.citationIdsValue.indexOf(this.focusedCitationIdValue)
    if (this.currentIndex === -1) this.currentIndex = 0

    this.currentCitationId = this.currentCitationIds[this.currentIndex]

    this.jumpToCurrentCitation()
    this.updateButtons()

    this.scrollContainerTarget.addEventListener('scroll', event => {
      this.updateIvarsFromScrollPosition()
    })
  }

  prevCitation () {
    if (this.currentIndex === 0) return

    this.currentIndex--
    this.currentCitationId = this.currentCitationIds[this.currentIndex]
    this.scrollToCurrentCitation()
  }

  nextCitation () {
    if (this.currentIndex === this.currentCitationIds.length - 1) return

    this.currentIndex++
    this.currentCitationId = this.currentCitationIds[this.currentIndex]
    this.scrollToCurrentCitation()
  }

  refreshPagination () {
    this.updateCurrentCitationIds()

    if (this.currentCitationIds.length === 0) {
      this.element.hidden = true
      return
    } else {
      this.element.hidden = false
    }

    // Move the current index to the citation that we were viewing if it's still visible, otherwise the first citation
    const indexOfLastViewedCitation = this.currentCitationIds.indexOf(this.currentCitationId)
    if (indexOfLastViewedCitation === -1) {
      this.currentIndex = 0
      this.currentCitationId = this.currentCitationIds[0]
    } else {
      this.currentIndex = indexOfLastViewedCitation
    }

    this.updatePageNumbers()
    this.updateButtons()
  }

  // Utility Methods

  jumpToCurrentCitation () {
    const citationWidth = this.scrollContainerTarget.children[0].offsetWidth
    this.scrollContainerTarget.scrollTo(citationWidth * this.currentIndex, 0)
  }

  scrollToCurrentCitation () {
    this.element.querySelector(`[data-citation-id="${this.currentCitationId}"]`)
      .scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  updateButtons () {
    if (this.currentCitationIds.length === 1) {
      this.leftButtonTarget.hidden = true
      this.rightButtonTarget.hidden = true
    } else if (this.currentIndex === 0) {
      this.leftButtonTarget.hidden = true
      this.rightButtonTarget.hidden = false
    } else if (this.currentIndex === this.currentCitationIds.length - 1) {
      this.leftButtonTarget.hidden = false
      this.rightButtonTarget.hidden = true
    } else {
      this.leftButtonTarget.hidden = false
      this.rightButtonTarget.hidden = false
    }
  }

  updatePageNumbers () {
    const totalCitations = this.currentCitationIds.length
    const visibleCitations = this.citationContainerTargets.filter(citationContainer => { return !citationContainer.hidden })

    if (totalCitations === 1) {
      visibleCitations[0].querySelector('[data-ujs-id="citation_page_indicator"]').hidden = true
      return
    }

    visibleCitations.forEach((citationContainer, i) => {
      citationContainer.querySelector('[data-ujs-id="citation_page_indicator"]').hidden = false
      citationContainer.querySelector('[data-ujs-id="citation_index"]').innerHTML = i + 1
      citationContainer.querySelector('[data-ujs-id="citations_count"]').innerHTML = totalCitations
    })
  }

  updateCurrentCitationIds () {
    const indicesToRemove = []
    this.citationContainerTargets.forEach((citationContainer, i) => {
      if (citationContainer.hidden) {
        indicesToRemove.push(i)
      }
    })

    const currentIds = [...this.citationIdsValue]
    for (let i = indicesToRemove.length - 1; i >= 0; i--) {
      currentIds.splice(indicesToRemove[i], 1)
    }

    this.currentCitationIds = currentIds
  }

  updateIvarsFromScrollPosition () {
    const citationWidth = this.citationContainerTargets.find(container => { return !container.hidden }).offsetWidth
    const scrollPosition = this.scrollContainerTarget.scrollLeft

    this.currentIndex = Math.round(scrollPosition / citationWidth)

    if (this.currentIndex > this.currentCitationIds.length - 1) {
      this.currentIndex = this.currentCitationIds.length - 1
    }
    this.currentCitationId = this.currentCitationIds[this.currentIndex]
    this.updateButtons()
    this.updateCommittedToClassificationCss()
  }

  updateCommittedToClassificationCss () {
    const currentCitationCommitted =
      this.element.querySelector(`[data-citation-id="${this.currentCitationId}"]`)
        .classList.contains('is-committed-to-classification')
    const citationsContainer = this.element.querySelector('[data-ujs-id="base_and_extracted_citations_container"]')

    if (currentCitationCommitted) {
      citationsContainer.classList.add('is-committed-to-classification')
    } else {
      citationsContainer.classList.remove('is-committed-to-classification')
    }
  }
}
