import { useClickOutside } from 'stimulus-use'
import BaseController from '../base_controller'

// Calculate startDate when clicking 1y, 2y, 5y buttons
function calculateStartDate (durationInYears) {
  // start date goes back [durationInYears] number of years
  const startDate = new Date()
  startDate.setFullYear(startDate.getFullYear() - durationInYears)
  const startDateString = convertDateToString(startDate)

  return startDateString
}

function todayDateString () {
  return convertDateToString(new Date())
}

// Convert a Date object into String format used for date input fields
function convertDateToString (date) {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
  const year = date.getFullYear()

  // return date string in yyyy-mm-dd format
  return year + '-' + month + '-' + day
}

export default class extends BaseController {
  static targets = [
    'searchTerm',
    'searchTermForDisplay',
    'filterModal',
    'universeSelect',
    'startDateInput',
    'endDateInput',
    'dateButton'
  ]

  static values = {
    start: String,
    end: String
  }

  connect () {
    super.connect()

    useClickOutside(this)
  }

  // Hide the Universe Filters modal
  closeModal () {
    this.filterModalTarget.hidden = true
  }

  // Show or hide the Universe Filters modal
  toggleModal () {
    this.filterModalTarget.hidden = !this.filterModalTarget.hidden
  }

  // Trigger form submission
  submitForm () {
    // toggle all modal input elements to be 'disabled' so they are not sent back as parameters
    this.searchTermForDisplayTarget.disabled = true
    this.universeSelectTarget.disabled = true
    this.startDateInputTarget.disabled = true
    this.endDateInputTarget.disabled = true

    this.element.closest('form').submit()
  }

  // Update the search term field to be submitted when we submit the form
  updateSearchTerm () {
    const universeName = this.universeSelectTarget.selectedOptions[0].label
    const universeId = this.universeSelectTarget.value
    const startDate = this.startDateInputTarget.value
    const endDate = this.endDateInputTarget.value

    this.searchTermForDisplayTarget.innerHTML = universeName

    if (startDate && endDate) {
      // format is [universeId] | [startDate]:[endDate]
      const dateRange = startDate + ':' + endDate
      this.searchTermTarget.value = universeId + ' | ' + dateRange
    } else {
      this.searchTermTarget.value = universeId
    }
  }

  universeSelectionChanged () {
    if (this.universeSelectTarget.value) {
      // enable dates when user selects an Universe option besides "Any"
      this.setDisabledStateForAllDateInputs(false)
      this.setDisabledStateForAllDateButtonss(false)
    } else {
      // disable date inputs when selecting "Any" option
      this.setDisabledStateForAllDateInputs(true)
      this.setDisabledStateForAllDateButtonss(true)

      // also clear out startDate and endDate inputs
      this.startDateInputTarget.value = ''
      this.endDateInputTarget.value = ''
    }

    this.updateSearchTerm()
    this.submitForm()
  }

  updateMinimumEndDate () {
    this.endDateInputTarget.min = this.startDateInputTarget.value
  }

  updateMaximumStartDate () {
    this.startDateInputTarget.max = this.endDateInputTarget.value
  }

  startDateKeyDown (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.updateStartValue()
    }
  }

  endDateKeyDown (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.updateEndValue()
    }
  }

  // compares and then updates this controller's startValue
  // if comparison sees startValue being changed, submit the form, else no need to submit
  updateStartValue () {
    const startValueChanged = this.startValue !== this.startDateInputTarget.value
    this.startValue = this.startDateInputTarget.value

    const startAndEndDateBothExist = this.startDateInputTarget.value && this.endDateInputTarget.value

    if (startValueChanged && startAndEndDateBothExist) {
      this.updateSearchTerm()
      this.submitForm()
    }
  }

  // compares and then updates this controller's endValue
  // if comparison sees endValue being changed, submit the form, else no need to submit
  updateEndValue () {
    const endValueChanged = this.endValue !== this.endDateInputTarget.value
    this.endValue = this.endDateInputTarget.value

    const startAndEndDateBothExist = this.startDateInputTarget.value && this.endDateInputTarget.value

    if (endValueChanged && startAndEndDateBothExist) {
      this.updateSearchTerm()
      this.submitForm()
    }
  }

  // Action for clicking 1y, 2y, 5y date buttons
  dateButtonClicked (event) {
    event.preventDefault()

    const startDate = calculateStartDate(event.params.years)
    const endDate = todayDateString()

    // update startDate/endDate input fields
    this.startDateInputTarget.value = startDate
    this.endDateInputTarget.value = endDate

    this.updateSearchTerm()
    this.submitForm()
  }

  // Disable both startDate and endDate input fields
  setDisabledStateForAllDateInputs (disabledState) {
    this.startDateInputTarget.disabled = disabledState
    this.endDateInputTarget.disabled = disabledState
  }

  // Disable all 3 date buttons
  setDisabledStateForAllDateButtonss (disabledState) {
    this.dateButtonTargets.forEach(element => {
      element.disabled = disabledState
    })
  }
}

export { calculateStartDate, todayDateString }
