import BaseController from '../base_controller'

export default class HighlightCitationController extends BaseController {
  unhighlightCitation () {
    const highlightedElements = document.querySelectorAll('.highlighted-citation')

    highlightedElements.forEach((highlightedElement) => {
      highlightedElement.classList.remove('highlighted-citation')
    })
  }

  setHighlightedCitation (event) {
    if (!event.detail?.citationId) return

    this.highlightCitation(event.detail.citationId)
  }

  highlightCitation (citationId) {
    this.unhighlightCitation()

    const citationToHighlight =
      document.querySelector('[data-ujs-id="citation_assistant_' + citationId + '_form"]')
        .closest('.edit-new-citation-forms-section')

    citationToHighlight.classList.add('highlighted-citation')
    citationToHighlight.scrollIntoView({ behavior: 'smooth' })
  }
}
